<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 12 : 2">
      <v-img height="200px" :src="item.bannerImg"></v-img>
      <v-card-subtitle class="pb-0">
        <v-chip :color="item.category.color">{{ item.category.name }}</v-chip>
      </v-card-subtitle>
      <v-card-title>{{ item.title }}</v-card-title>
      <v-card-text class="text--primary">
        {{ item.subtitle }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text>
          <router-link class="nav-link primary--text" :to="item.link">See Details</router-link>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'FeatureProject',
  props: {
    item: {
      title: String,
      subtitle: String,
      bannerImg: String,
      link: String,
      category: {
        name: String,
        color: String
      }
    }
  }
}
</script>

<style scoped>

</style>
