import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Hume Built - Home'
    }
  },
  {
    path: '/privacy-notice',
    name: 'Privacy Notice',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      title: 'Hume Built - Privacy Notice'
    }
  },
  {
    path: '/terms-of-service',
    name: 'Terms of Service',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      title: 'Hume Built - Terms of Service'
    }
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'Hume Built - About Us'
    }
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: {
      title: 'Hume Built - Contact Us'
    }
  },
  {
    path: '/our-service',
    name: 'Our Service',
    component: () => import(/* webpackChunkName: "service" */ '../views/Service.vue'),
    meta: {
      title: 'Hume Built - Our Service'
    }
  },
  {
    path: '/news/:slug',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
    meta: {
      title: 'Hume Built - News'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projectList" */ '../views/ProjectList.vue'),
    meta: {
      title: 'Hume Built - Our Projects'
    }
  },
  {
    path: '/projects/:slug',
    name: 'Project',
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
    meta: {
      title: 'Hume Built - Project'
    }
  },
  {
    path: '/:slug',
    name: 'Content',
    component: () => import(/* webpackChunkName: "content" */ '../views/Content.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  beforeEach (toRoute, fromRoute, next) {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Hume Built - Home'
    next()
  }
})

const DEFAULT_TITLE = 'Hume Built'
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
