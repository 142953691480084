import Vue from 'vue'
import Vuex from 'vuex'
import navigationConfig from '../mock/NavigationConfig.json'
import footerConfig from '../mock/FooterConfig.json'
import carouselConfig from '../mock/CarouselConfig.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navigationConfig: Object,
    footerConfig: Object,
    carouselConfig: Object
  },
  mutations: {
    SET_NAVIGATION_CONFIG (state, config) {
      state.navigationConfig = config
    },
    SET_FOOTER_CONFIG (state, config) {
      state.footerConfig = config
    },
    SET_CAROUSEL_CONFIG (state, config) {
      state.carouselConfig = config
    }
  },
  actions: {
    fetchNavigationConfig (context) {
      context.commit('SET_NAVIGATION_CONFIG', navigationConfig)
    },
    fetchFooterConfig (context) {
      context.commit('SET_FOOTER_CONFIG', footerConfig)
    },
    fetchCarouselConfig (context) {
      context.commit('SET_CAROUSEL_CONFIG', carouselConfig)
    }
  },
  modules: {
  }
})
