<template>
  <v-footer :color="footerConfig.backgroundColour">
    <v-btn v-for="(menu, index) in footerConfig.menu.items"
           text
           :key="'footer-menu-' + index">
      <router-link :to="menu.link"
                   class="nav-link"
                   :class="footerConfig.menu.textColour">
        {{ menu.label }}
      </router-link>
    </v-btn>
    <v-spacer></v-spacer>
    <div class="text-center">
      <v-btn icon v-for="(menu, index) in footerConfig.socialMenu.items"
             :color="footerConfig.socialMenu.iconColour"
             :key="'social-menu-' + index"
             :href="menu.link">
        <v-icon>{{ menu.icon }}</v-icon>
      </v-btn>
    </div>
    <v-spacer></v-spacer>
    <div class="text-body-2 white--text">© 2021 Hume Built Pty Ltd. Powered by Lions System.</div>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SiteFooter',
  computed: {
    ...mapState(['footerConfig'])
  }
}
</script>

<style scoped>

</style>
