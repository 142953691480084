<template>
  <v-main>
    <v-container fluid class="fill-width">
      <!-- Start Carousel -->
      <v-carousel cycle continuous :height="carouselConfig.height" show-arrows-on-hover dark>
        <v-carousel-item v-for="(carousel, index) in carouselConfig.items"
                         :key="'carousel-' + index"
                         :src="carousel.imageUrl">
          <v-row class="fill-height" align="end" justify="center">
            <v-card color="rgba(255, 255, 255, 0.75)" class="mb-12 py-4 px-12 text-center">
              <div class="text-h4 grey--text text--darken-4">{{ carousel.title }}</div>
              <div class="text-h6 grey--text text--darken-4">{{ carousel.subtitle }}</div>
            </v-card>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <!-- End Carousel -->
      <!-- Start Feature Blog -->
      <v-container>
        <v-row justify="center">
          <v-col v-for="(item, index) in news.items" :key="'news-' + index"
                 cols="12" md="5">
            <feature-post :item="item"></feature-post>
          </v-col>
        </v-row>
      </v-container>
      <!-- End Feature Blog -->
      <v-divider></v-divider>
      <!-- Start Feature Project -->
      <v-container fluid class="row-dark">
        <v-container>
          <v-row justify="center">
            <div class="text-h5 my-4">Our Projects</div>
          </v-row>
          <v-row justify="center">
            <v-col v-for="(item, index) in projects.items" :key="'project-' + index"
                   cols="12" md="6" lg="4">
              <feature-project :item="item"></feature-project>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <!-- End Feature Project -->
      <v-divider></v-divider>
      <!-- Start Subscribe -->
      <v-card outlined tile>
        <v-img :max-height="$vuetify.breakpoint.mdAndDown ? 800 : 600" src="../assets/card-bg5.jpg" gradient="to bottom, rgba(222, 222, 222, 0.9), rgba(240, 240, 240, 0.8)">
          <v-container>
            <v-row justify="center">
              <div class="text-h5 my-4">Got a project? Let's work together.</div>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-form ref="contactForm" v-model="isFormValid">
                  <v-row justify="center">
                    <v-col cols="8" md="4">
                      <v-text-field :rules="validationRules.name" counter="25" hint="Please enter your name" label="Name"></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4">
                      <v-text-field :rules="validationRules.company" counter="25" hint="Please enter your company name" label="Company"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="8" md="4">
                      <v-text-field :rules="validationRules.email" type="email" counter="25" hint="Your email address" label="Email"></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4">
                      <v-text-field :rules="validationRules.mobile" counter="25" hint="Your phone or mobile number" label="Phone"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="8">
                      <v-textarea filled auto-grow counter="500" hint="Summary about your project" label="Project Summary"></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn color="primary" @click="submitForm" :disabled="isFormSubmitted">Submit</v-btn>
            </v-row>
            <v-row class="my-4" justify="center" v-if="isFormSubmitted">
              <v-alert type="success">Your request has been submitted. Thank you.</v-alert>
            </v-row>
          </v-container>
        </v-img>
      </v-card>
      <!-- End Subscribe -->
      <v-divider></v-divider>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import featuredNews from '../mock/FeaturedNewsConfig.json'
import FeaturePost from '../components/FeaturePost'
import featuredProjects from '../mock/FeaturedProjectConfig.json'
import FeatureProject from '../components/FeatureProject'

export default {
  name: 'Home',
  components: {
    FeatureProject,
    FeaturePost

  },
  data: () => ({
    news: featuredNews,
    projects: featuredProjects,
    isFormValid: true,
    isFormSubmitted: false,
    validationRules: {
      name: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 25) || 'Name must be less than 25 characters'
      ],
      company: [
        v => !!v || 'Company is required',
        v => (v && v.length <= 10) || 'Company must be less than 25 characters'
      ],
      email: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ],
      mobile: [
        v => !!v || 'Mobile is required',
        v => /(\(+61\)|\+61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){6,9}/.test(v) || 'Mobile must be valid'
      ]
    }
  }),
  created () {
    this.fetchCarouselConfig()
  },
  methods: {
    ...mapActions(['fetchCarouselConfig']),
    submitForm () {
      this.$refs.contactForm.validate()
      this.isFormSubmitted = this.isFormValid
    }
  },
  computed: {
    ...mapState(['carouselConfig'])
  }
}
</script>

<style scoped>
.fill-width {
  padding: 0;
}
.row-dark {
  background-color: #F5F5F5;
}
</style>
