<template>
  <v-app-bar fixed light color="transparent" elevate-on-scroll>
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to bottom, rgba(255,242,242,.95), rgba(240,240,240,0.8)"
      ></v-img>
    </template>

    <v-img max-width="128" :src="navigationConfig.logo"></v-img>

    <a href="/"
       class="nav-link"
       :class="navigationConfig.textColour">
      <v-toolbar-title class="mx-4 title">{{ navigationConfig.title }}</v-toolbar-title>
    </a>

    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn text v-for="(menu, index) in navigationConfig.menu" :key="'nav-menu-' + index">
        <router-link :to="menu.link"
                     class="nav-link"
                     :class="navigationConfig.textColour">
          {{ menu.label }}
        </router-link>
      </v-btn>
    </v-toolbar-items>
    <v-menu class="hidden-md-and-up" v-if="$vuetify.breakpoint.mdAndDown">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(menu, index) in navigationConfig.menu" :key="'nav-menu-toggled-' + index">
          <v-list-item-title>{{ menu.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'NavBar',
  data () {
    return {

    }
  },
  computed: {
    ...mapState(['navigationConfig'])
  }
}
</script>

<style scoped>
.title {
  font-weight: 500;
}
</style>
