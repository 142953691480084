<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 12 : 2">
      <v-img :src="item.bannerImg"
             class="grey--text text--lighten-4 align-center"
             :gradient="hover ? 'to bottom, rgba(0, 0, 0,.4), rgba(0, 0, 0,.4)' : 'to bottom, rgba(0, 0, 0,.7), rgba(0, 0, 0,.7)'">
        <v-container class="text-center">
          <v-card-subtitle>
            <v-chip :color="item.category.color">{{ item.category.name }}</v-chip>
          </v-card-subtitle>
          <v-card-title class="text-h5 justify-center">{{ item.title }}</v-card-title>
          <v-card-text>{{ item.subtitle }}</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn dark color="primary">
              <router-link class="nav-link white--text" :to="item.link">Read more</router-link>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-img>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'FeaturePost',
  props: {
    item: {
      title: String,
      subtitle: String,
      bannerImg: String,
      link: String,
      date: String,
      category: {
        name: String,
        color: String
      }
    }
  }
}
</script>

<style scoped>

</style>
