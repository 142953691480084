import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.red.darken4,
        secondary: colors.red.base,
        accent: colors.indigo.accent2,
        error: colors.red.accent4,
        warning: colors.yellow.darken3,
        info: colors.cyan.lighten2,
        success: colors.green.base
      },
      dark: {
        primary: colors.red.darken4,
        secondary: colors.red.base,
        accent: colors.indigo.accent2,
        error: colors.red.accent4,
        warning: colors.yellow.darken3,
        info: colors.cyan.lighten2,
        success: colors.green.base
      }
    }
  }
})
