<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main>
      <router-view/>
    </v-main>
    <site-footer></site-footer>
  </v-app>
</template>

<script>

import NavBar from '@/components/NavBar'
import SiteFooter from '@/components/SiteFooter'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: { SiteFooter, NavBar },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions(['fetchNavigationConfig', 'fetchFooterConfig'])
  },
  created () {
    this.fetchNavigationConfig()
    this.fetchFooterConfig()
  }
}
</script>
<style>
.router-link-active {
  text-decoration-line: none;
}

.nav-link {
  text-decoration-line: none;
}
</style>
